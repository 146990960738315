import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import InputForm from "../FormComponents/InputForm";
import { Checkbox, Alert, Radio, FormControlLabel } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  addDataToValidatedCartAction,
  storeCreditCardSplitPaymentsAction,
  storeCashSplitPaymentsAction,
  splitItemsPaymentsAction,
  showSplitPaymentSectionAction,
  showSplitItemsSectionAction,
  showCashSectionAction,
  setReamainigValueAction,
  setPaidValueAction,
  removeSplitPaymentItemAction,
  disablePayAmountInputAction,
} from "../../Redux/Actions";
import { initiatePaymentMutation } from "../../graphql/mutations";
import Swal from "sweetalert2";
import paymentStatus from "../../Helpers/Constants/PaymentConstants";

const PaymentStep = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  // states from store
  const validatedCart = useSelector(
    (state) => state?.main?.checkout?.validatedCart
  );

  const cartTotalAmount = useSelector(
    (state) => state?.main?.checkout?.checkoutFormData?.cartTotalAmount
  );

  const showSplitPaymentSection = useSelector(
    (state) => state?.main?.checkout?.showSplitPaymentSection
  );

  const showSplitItemsSection = useSelector(
    (state) => state?.main?.checkout?.showSplitItemsSection
  );

  const showCashSection = useSelector(
    (state) => state?.main?.checkout?.showCashSection
  );

  const remainingValue = useSelector(
    (state) => state?.main?.checkout?.remainingValueOfSplitPayment
  );

  const paidValue = useSelector(
    (state) => state?.main?.checkout?.paidValueOfSplitPayment
  );

  const creditCardSplitPayments = useSelector(
    (state) => state?.main?.checkout?.creditCardSplitPayments
  );

  const cashSplitPayments = useSelector(
    (state) => state?.main?.checkout?.cashSplitPayments
  );

  const itemsSplitPayments = useSelector(
    (state) => state?.main?.checkout?.itemsSplitPayments
  );

  const paidSplitItems = useSelector(
    (state) => state?.main?.checkout?.paidSplitItems
  );

  const stationInfo = useSelector((state) => state?.main?.station?.stationInfo);

  const customerFormData = useSelector(
    (state) => state?.main?.checkout?.checkoutFormData?.customerForm
  );

  const disablePayAmountInput = useSelector(
    (state) => state?.main?.checkout?.disablePayAmountInput
  );

  const splitPaymentAmountValue = useSelector(
    (state) => state?.main?.checkout?.splitPaymentAmountValue
  );

  const splitByItemIndex = useSelector(
    (state) => state?.main?.checkout?.splitByItemIndex
  );

  const stationStripeInfo = useSelector(
    (state) => state?.main?.station?.stationStripeInfo
  );

  const stripeTerminalInfo = useSelector(
    (state) => state?.main?.station?.stripeTerminalInfo
  );

  // init useStates of payment form
  const [paymentFormValues, setPaymentFormValues] = useState({
    paid_amount: "",
    change: "",
    pay: "",
    item_selected_index: "",
  });

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cashInputErrorMessage, setCashInputErrorMessage] = useState("");

  const [creditCardErrorMessage, setCreditCardErrorMessage] = useState("");

  const [terminalLoader, setTerminalLoader] = useState(false);

  const [afterPayLoader, setAfterPayLoader] = useState(false);

  const [showPaymentCompleted, setPaymentCompleted] = useState(false);

  // handle toggle between full/split payment sections
  const handleToggleBetweenSplitFullPaymentSections = () => {
    dispatch(showSplitPaymentSectionAction(!showSplitPaymentSection));

    // toggle to the default tab
    dispatch(showCashSectionAction("creditCardSection"));

    // reset all values
    setPaymentFormValues(() => ({
      paid_amount: "",
      change: "",
      pay: "",
      item_selected_index: "",
    }));

    setPaymentCompleted(false);

    setMessage(null);

    dispatch(disablePayAmountInputAction(false, 0));
  };

  // handle toggle between credit card/cash/afterpay sections
  const handleToggleBetweenCashCreditCardSections = (sectionName) => {
    dispatch(showCashSectionAction(sectionName));

    // reset cash error message
    setCashInputErrorMessage("");

    // reset error message
    setCreditCardErrorMessage("");

    // reset all values
    setPaymentFormValues(() => ({
      paid_amount: "",
      change: "",
      pay: "",
      item_selected_index: "",
    }));

    setPaymentCompleted(false);

    setMessage(null);

    dispatch(disablePayAmountInputAction(false, 0));
  };

  // handle toggle between split total / items sections
  const handleToggleBetweenSplitTotalAndItemsSections = () => {
    dispatch(showSplitItemsSectionAction(!showSplitItemsSection));

    // reset all values
    setPaymentFormValues(() => ({
      paid_amount: "",
      change: "",
      pay: "",
      item_selected_index: "",
    }));

    setPaymentCompleted(false);

    setMessage(null);

    dispatch(disablePayAmountInputAction(false, 0));
  };

  // handle inputs form component change
  const handleInputOnChange = (name, value) => {
    setPaymentFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle piad amount in split items calculations
  useEffect(() => {
    // to set the paid amout input value as the paid
    setPaymentFormValues((prevState) => ({
      ...prevState,
      paid_amount: showSplitItemsSection ? "" : paymentFormValues?.pay,
    }));
  }, [paymentFormValues?.pay]);

  // handle change calculations
  useEffect(() => {
    if (
      paymentFormValues?.paid_amount === "" ||
      +paymentFormValues?.paid_amount < +cartTotalAmount
    ) {
      setPaymentFormValues((prevState) => ({
        ...prevState,
        change: "",
      }));
    }

    if (
      +paymentFormValues?.paid_amount >= +cartTotalAmount &&
      +paidValue === 0
    ) {
      setPaymentFormValues((prevState) => ({
        ...prevState,
        change: +paymentFormValues?.paid_amount - +(+cartTotalAmount),
      }));
    }

    // for split payment case section
    if (+paymentFormValues?.pay > +remainingValue && +paidValue > 0) {
      setPaymentFormValues((prevState) => ({
        ...prevState,
        change: +paymentFormValues?.pay - +(+remainingValue),
      }));
    }
  }, [paymentFormValues?.paid_amount]);

  // handle checkbox change
  const handleCheckboxChange = (event, itemPrice, i) => {
    dispatch(
      splitItemsPaymentsAction(event?.target?.checked, "item_selected", i)
    );

    // set the pay state to the selected items price
    setPaymentFormValues((prevState) => ({
      ...prevState,
      pay: (+itemPrice + +validatedCart?.shipping?.amount)?.toFixed(2),
      item_selected_index: i,
    }));

    if (showCashSection === "creditCardSection") {
      // handle pay split by item
      handlePaySplitByItem(
        itemsSplitPayments?.length <= 1
          ? (+itemPrice + +validatedCart?.shipping?.amount)?.toFixed(2)
          : (+itemPrice)?.toFixed(2),
        i
      );
    }
  };

  // handle back btn
  const handleBackBtn = () => {
    // handle form step
    props?.handleBackToStep();

    // reset all values
    setPaymentFormValues(() => ({
      paid_amount: "",
      change: "",
      pay: "",
      item_selected_index: "",
    }));

    setPaymentCompleted(false);

    setMessage(null);

    dispatch(disablePayAmountInputAction(false, 0));
  };

  // handle use stripe terminal
  const handleUseStripeTerminal = async () => {
    setTerminalLoader(true);

    // for testing purposes
    if (
      stationStripeInfo?.filter((el) => el?.id === props?.station_id)[0]
        ?.test_terminal
    ) {
      stripeTerminalInfo?.terminal?.setSimulatorConfiguration({
        testCardNumber: "4242424242424242",
      });
    }
    const paymentMethod =
      await stripeTerminalInfo?.terminal?.collectPaymentMethod(
        props?.paymentIntentClientSecret
      );
    if (paymentMethod?.error) {
      setTerminalLoader(false);
      Swal.fire({
        title: "Error",
        text: paymentMethod?.error?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });
      console.log("collect payment error", paymentMethod?.error);
    } else {
      console.log(
        "terminal.collectPaymentMethod",
        paymentMethod?.paymentIntent
      );
      // process payment for stripe terminal
      const processedPayment =
        await stripeTerminalInfo?.terminal?.processPayment(
          paymentMethod.paymentIntent
        );
      if (processedPayment?.error) {
        setTerminalLoader(false);
        Swal.fire({
          title: "Error",
          text: processedPayment?.error?.message,
          timer: 3000,
          showConfirmButton: false,
          imageUrl: require("../../Assets/img/error-icon.png"),
          imageAlt: "Image not found",
          customClass: {
            popup: "swal-error-style",
            container: "swal-title",
          },
        });
        console.log("process payment error", processedPayment?.error);
      } else if (processedPayment?.paymentIntent) {
        setTerminalLoader(false);

        console.log("process payment intent", processedPayment?.paymentIntent);

        // handle checkout payment process
        if (!showSplitPaymentSection) {
          // handle add data to validated cart
          let data = {
            ...validatedCart,
            payments: [
              {
                method: "Credit Card",
                amount: +cartTotalAmount,
                details: {
                  transactionId: processedPayment?.paymentIntent?.id,
                  paymentMethodId:
                    processedPayment?.paymentIntent?.payment_method,
                },
              },
            ],
          };

          dispatch(addDataToValidatedCartAction({ data }));

          // to show payment completed alert
          setPaymentCompleted(true);

          // to navigate to finish step
          props?.handleNextStep();
        } else {
          if (
            +splitPaymentAmountValue === +cartTotalAmount ||
            +remainingValue?.toFixed(2) -
              (+splitPaymentAmountValue)?.toFixed(2) ===
              0 ||
            +splitPaymentAmountValue >= +cartTotalAmount
          ) {
            // handle add data to validated cart
            let data = {
              ...validatedCart,
              payments:
                creditCardSplitPayments?.length >= 1
                  ? [
                      ...creditCardSplitPayments,
                      {
                        method: "Credit Card",
                        amount: +splitPaymentAmountValue,
                        details: {
                          transactionId: processedPayment?.paymentIntent?.id,
                          paymentMethodId:
                            processedPayment?.paymentIntent?.payment_method,
                        },
                      },
                    ]
                  : [
                      {
                        method: "Credit Card",
                        amount: +splitPaymentAmountValue,
                        details: {
                          transactionId: processedPayment?.paymentIntent?.id,
                          paymentMethodId:
                            processedPayment?.paymentIntent?.payment_method,
                        },
                      },
                    ],
            };

            dispatch(addDataToValidatedCartAction({ data }));

            // to show payment completed alert
            setPaymentCompleted(true);

            // to navigate to finish step
            props?.handleNextStep();
          } else {
            // to store the credit card payments
            let data = {
              method: "Credit Card",
              amount: +splitPaymentAmountValue,
              details: {
                transactionId: processedPayment?.paymentIntent?.id,
                paymentMethodId:
                  processedPayment?.paymentIntent?.payment_method,
              },
            };

            // to caluclate the paid amount and remainig
            dispatch(setPaidValueAction(+paidValue + +splitPaymentAmountValue));

            dispatch(
              setReamainigValueAction(
                +remainingValue.toFixed(2) - +splitPaymentAmountValue
              )
            );

            dispatch(storeCreditCardSplitPaymentsAction({ data }));

            // to remove the paid item in the split items payment
            if (showSplitItemsSection) {
              dispatch(removeSplitPaymentItemAction(splitByItemIndex));
            }

            // reset the pay amount input after confirmation
            dispatch(disablePayAmountInputAction(false, 0));

            // handle init stripe again until remainig is zero
            // props?.handleInitPayment(
            //   paymentFormValues?.pay,
            //   paymentStatus.STRIPE_PAYMENT,
            //   "paymentStep"
            // );
          }
        }
      }
    }
  };

  // Start of init payment for after pay mutation
  const handleInitPaymentForAfterPay = () => {
    initiatePaymentForAfterPay({
      variables: {
        amount: +cartTotalAmount,
        // if it is 3 ==> means afterpay method
        method: 3,
        stationId: +stationInfo?.stationId,
        customer_id: +customerFormData?.id,
      },
    })
      .then((response) => {
        if (response?.errors || response?.initiatePayment?.status === 0) {
          // error swal
          Swal.fire({
            title: "Error",
            text: response?.errors[0]?.extensions?.validation,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    initiatePaymentForAfterPay,
    {
      data: initiatePaymentForAfterPayResponse,
      loading: initiatePaymentForAfterPayIsLoading,
      error: initiatePaymentForAfterPayError,
    },
  ] = useMutation(initiatePaymentMutation, {
    onCompleted: (data) => {
      if (data?.initiatePayment?.redirectUrl) {
        // open link in pop up window
        window.open(
          data?.initiatePayment?.redirectUrl,
          "Pop up window",
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1200, height=800`
        );
      }
    },
  });
  // End of handle init payment for after pay mutation

  // handle pay by amount in split method
  const handlePaySplitByAmount = (amountValuee) => {
    props?.handleInitPayment(
      paymentFormValues?.pay ? paymentFormValues?.pay : amountValuee,
      paymentStatus.STRIPE_PAYMENT,
      "paymentStep"
    );

    dispatch(disablePayAmountInputAction(true, paymentFormValues?.pay));
  };

  // handle pay by item in split method
  const handlePaySplitByItem = (itemPrice, itemIndex) => {
    props?.handleInitPayment(
      itemPrice,
      paymentStatus.STRIPE_PAYMENT,
      "paymentStep"
    );

    dispatch(disablePayAmountInputAction(true, itemPrice, itemIndex));
  };

  const handleSubmitBtn = async (e) => {
    e.preventDefault();

    // handle after-pay method
    if (showCashSection === "afterPaySection") {
      // init payment for after pay
      handleInitPaymentForAfterPay();

      setAfterPayLoader(true);

      // handle listen to message in the other tab to run the checkout mutation and get the transction id and the rest of detials
      window.addEventListener("message", (e) => {
        if (e?.data?.txn_id) {
          // to console data
          console.log(
            "User has completed payment from the other tab!",
            e?.data
          );

          setAfterPayLoader(false);

          // handle add data to validated cart
          let data = {
            ...validatedCart,
            payments: [
              {
                method: "AfterPay",
                amount: +cartTotalAmount,
                details: {
                  transactionId: e?.data?.txn_id,
                  paymentMethodId: "3",
                },
              },
            ],
          };

          dispatch(addDataToValidatedCartAction({ data }));

          // to show payment completed alert
          setPaymentCompleted(true);

          // to navigate to finish step
          props?.handleNextStep();
        }
      });

      return;
    }

    // handle cash method
    if (showCashSection === "cashSection") {
      // reset cash error message
      setCashInputErrorMessage("");

      if (!showSplitPaymentSection) {
        if (
          +paymentFormValues?.paid_amount < +cartTotalAmount ||
          paymentFormValues?.paid_amount === ""
        ) {
          // handle error message
          setCashInputErrorMessage("Please enter the full amount");
        } else {
          // handle add data to validated cart
          let data = {
            ...validatedCart,
            payments: [
              {
                method: "Cash",
                amount: +cartTotalAmount,
              },
            ],
          };

          dispatch(addDataToValidatedCartAction({ data }));

          // to show payment completed alert
          setPaymentCompleted(true);

          // to navigate to finish step
          props?.handleNextStep();
        }
      } else {
        if (
          +paymentFormValues?.pay === +cartTotalAmount ||
          +remainingValue?.toFixed(2) -
            (+paymentFormValues?.pay)?.toFixed(2) ===
            0 ||
          +paymentFormValues?.pay >= +cartTotalAmount ||
          +paidValue >= +cartTotalAmount ||
          (+paymentFormValues?.pay).toFixed(2) >= +remainingValue.toFixed(2)
        ) {
          // handle add data to validated cart
          let data = {
            ...validatedCart,
            payments: [
              ...creditCardSplitPayments,
              ...cashSplitPayments,
              {
                method: "Cash",
                amount: +paymentFormValues?.pay,
              },
            ],
          };

          dispatch(addDataToValidatedCartAction({ data }));

          // to show payment completed alert
          setPaymentCompleted(true);

          // to navigate to finish step
          props?.handleNextStep();
        } else {
          // to store the credit card payments
          let data = {
            method: "Cash",
            amount: +paymentFormValues?.pay,
          };

          // to caluclate the paid amount and remainig
          dispatch(setPaidValueAction(+paidValue + +paymentFormValues?.pay));

          dispatch(
            setReamainigValueAction(remainingValue - +paymentFormValues?.pay)
          );

          dispatch(storeCreditCardSplitPaymentsAction({ data }));

          // to remove the paid item in the split items payment
          if (showSplitItemsSection) {
            dispatch(
              removeSplitPaymentItemAction(
                paymentFormValues?.item_selected_index
              )
            );
          }

          // reset all values
          setPaymentFormValues(() => ({
            pay: "",
          }));
        }
      }

      return;
    }

    // handle credit card method using terminal
    if (
      showCashSection === "creditCardSection" &&
      stationStripeInfo?.filter((el) => el?.id === props?.station_id)[0]
        ?.stripe_location_id !== null &&
      stationStripeInfo?.filter((el) => el?.id === props?.station_id)[0]
        ?.terminal_type === paymentStatus.STRIPE_PAYMENT &&
      stationStripeInfo?.filter((el) => el?.id === props?.station_id)[0]
        ?.is_terminal_connected
    ) {
      // handle use stripe terminal function
      handleUseStripeTerminal();

      return;
    }

    // to check if the amount entered is more than the total
    if (
      showSplitPaymentSection &&
      (+splitPaymentAmountValue).toFixed(2) > +remainingValue.toFixed(2)
    ) {
      setCreditCardErrorMessage("You have entered more than the total price.");

      return;
    }

    // handle credit card method
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   return_url: `${window.location.origin}/store-fronts`,
      // },
      redirect: "if_required",
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    } else if (paymentIntent) {
      // reset error message
      setCreditCardErrorMessage("");

      if (!showSplitPaymentSection) {
        // handle add data to validated cart
        let data = {
          ...validatedCart,
          payments: [
            {
              method: "Credit Card",
              amount: +cartTotalAmount,
              details: {
                transactionId: paymentIntent?.id,
                paymentMethodId: paymentIntent?.payment_method,
              },
            },
          ],
        };

        dispatch(addDataToValidatedCartAction({ data }));

        // to show payment completed alert
        setPaymentCompleted(true);

        // to navigate to finish step
        props?.handleNextStep();
      } else {
        if (
          +splitPaymentAmountValue === +cartTotalAmount ||
          +remainingValue?.toFixed(2) -
            (+splitPaymentAmountValue)?.toFixed(2) ===
            0
        ) {
          // handle add data to validated cart
          let data = {
            ...validatedCart,
            payments: [
              ...creditCardSplitPayments,
              ...cashSplitPayments,
              {
                method: "Credit Card",
                amount: +splitPaymentAmountValue,
                details: {
                  transactionId: paymentIntent?.id,
                  paymentMethodId: paymentIntent?.payment_method,
                },
              },
            ],
          };

          dispatch(addDataToValidatedCartAction({ data }));

          // to show payment completed alert
          setPaymentCompleted(true);

          // to navigate to finish step
          props?.handleNextStep();
        } else {
          // to store the credit card payments
          let data = {
            method: "Credit Card",
            amount: +splitPaymentAmountValue,
            details: {
              transactionId: paymentIntent?.id,
              paymentMethodId: paymentIntent?.payment_method,
            },
          };

          // to caluclate the paid amount and remainig
          dispatch(setPaidValueAction(+paidValue + +splitPaymentAmountValue));

          dispatch(
            setReamainigValueAction(
              +remainingValue.toFixed(2) - +splitPaymentAmountValue
            )
          );

          dispatch(storeCreditCardSplitPaymentsAction({ data }));

          // to remove the paid item in the split items payment
          if (showSplitItemsSection) {
            dispatch(removeSplitPaymentItemAction(splitByItemIndex));
          }

          // reset the pay amount input after confirmation
          dispatch(disablePayAmountInputAction(false, 0));

          // handle init stripe again until remainig is zero
          // props?.handleInitPayment(
          //   paymentFormValues?.pay,
          //   paymentStatus.STRIPE_PAYMENT,
          //   "paymentStep"
          // );
        }
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <div className="row step_form_wrapper_style checkout_payment_step_wrapper_style">
        {showPaymentCompleted ? (
          <div className="payment_completed_wrapper_style">
            <img src={require("../../Assets/img/check-mark-icon.png")} />

            <p>Payment completed - you're finished</p>
          </div>
        ) : (
          <>
            <div className="col-12">
              <div className="d-flex">
                <Button
                  className={
                    showSplitPaymentSection
                      ? "w-100 pay_in_full_btn_style"
                      : "w-100 pay_in_full_btn_style active"
                  }
                  onClick={handleToggleBetweenSplitFullPaymentSections}
                  disabled={!showSplitPaymentSection}
                  datatestid="pay_in_full_in_payment_step_btn"
                >
                  Pay in Full
                </Button>

                <Button
                  className={
                    showSplitPaymentSection
                      ? "w-100 split_payment_btn_style active"
                      : "w-100 split_payment_btn_style"
                  }
                  onClick={handleToggleBetweenSplitFullPaymentSections}
                  datatestid="split_payments_in_payment_step_btn"
                  disabled={showSplitPaymentSection}
                >
                  Split Payment
                </Button>
              </div>

              {showSplitPaymentSection ? (
                <>
                  <div className="d-flex w-50">
                    <Button
                      className={
                        showSplitItemsSection
                          ? "w-100 split_total_items_btn_style"
                          : "w-100 split_total_items_btn_style active"
                      }
                      onClick={handleToggleBetweenSplitTotalAndItemsSections}
                      disabled={!showSplitItemsSection}
                      datatestid="total_split_in_full_in_payment_step_btn"
                    >
                      Amount
                    </Button>

                    <Button
                      className={
                        showSplitItemsSection
                          ? "w-100 split_total_items_btn_style active"
                          : "w-100 split_total_items_btn_style"
                      }
                      onClick={handleToggleBetweenSplitTotalAndItemsSections}
                      datatestid="items_split_in_full_in_payment_step_btn"
                      disabled={showSplitItemsSection}
                    >
                      Items
                    </Button>
                  </div>

                  {showSplitItemsSection ? (
                    <div className="row">
                      <h2>Split by item</h2>

                      <div className="col-12">
                        <div className="split_by_items_payment_wrapper_style pe-4">
                          {paidSplitItems
                            ?.filter((el) => el?.payment_status === "paid")
                            ?.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="d-flex align-items-center justify-content-between paid_items_wrapper_style"
                                >
                                  <div>
                                    <p>{item?.itemDetails?.name}</p>
                                  </div>

                                  <div>
                                    <p>
                                      $
                                      {(
                                        +item?.itemDetails?.price *
                                          +item?.itemDetails?.quantity +
                                        +item?.itemDetails?.tax *
                                          +item?.itemDetails?.quantity
                                      )?.toFixed(2)}
                                    </p>
                                  </div>

                                  <div>
                                    <p>qty ({item?.itemDetails?.quantity})</p>
                                  </div>

                                  <div className="paid_badge_style">Paid</div>
                                </div>
                              );
                            })}

                          {itemsSplitPayments?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className={
                                  item?.item_selected
                                    ? "d-flex align-items-center justify-content-between items_split_wrapper_style active"
                                    : "d-flex align-items-center justify-content-between items_split_wrapper_style"
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <Checkbox
                                    name="item_selected"
                                    sx={{
                                      color: "#b7c0cd",
                                      "&.Mui-checked": {
                                        color: "#3e8ef7",
                                      },
                                    }}
                                    checked={item?.item_selected}
                                    datatestid={`items-split-in-full-in-payment-step-radio-btn-index-${i}`}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e,
                                        (
                                          +item?.itemDetails?.price *
                                            +item?.itemDetails?.quantity +
                                          +item?.itemDetails?.tax *
                                            +item?.itemDetails?.quantity
                                        )?.toFixed(2),
                                        i
                                      )
                                    }
                                  />

                                  <div>
                                    <p>{item?.itemDetails?.name}</p>
                                  </div>
                                </div>

                                <div>
                                  <p>
                                    $
                                    {(
                                      +item?.itemDetails?.price *
                                        +item?.itemDetails?.quantity +
                                      +item?.itemDetails?.tax *
                                        +item?.itemDetails?.quantity
                                    )?.toFixed(2)}
                                  </p>
                                </div>

                                <div>
                                  <p>qty ({item?.itemDetails?.quantity})</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="col-12 d-flex justify-content-end">
                        <div className="mx-3">
                          <div className="d-flex align-items-center justify-content-center paid_remaning_wrapper_style">
                            <label
                              className="pe-3"
                              datatestid="items_paid_label_in_payment_step"
                            >
                              Paid = ${+paidValue?.toFixed(2)}
                            </label>
                          </div>
                        </div>

                        <div>
                          <div className="d-flex align-items-center justify-content-center paid_remaning_wrapper_style">
                            <label
                              className="pe-3"
                              datatestid="items_remaining_label_in_payment_step"
                            >
                              Remaining = ${+remainingValue?.toFixed(2)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row align-items-center">
                      <h2>Split by Amount</h2>

                      <div className="col-12 col-md-6">
                        <div className="d-flex">
                          <div className="custom_input_form_wrapper_style me-2">
                            <label>Pay</label>

                            <InputForm
                              type="number"
                              name="pay"
                              placeholder="Amount"
                              onWheel={(e) => e.target.blur()}
                              disabled={disablePayAmountInput}
                              value={
                                disablePayAmountInput
                                  ? splitPaymentAmountValue
                                  : paymentFormValues?.pay
                              }
                              datatestid="pay_cash_in_payment_step_input"
                              handleInputOnChange={handleInputOnChange}
                            />
                          </div>

                          {showCashSection === "creditCardSection" ? (
                            <>
                              {disablePayAmountInput ? (
                                <Button
                                  className="disable_split_payment_btn_style"
                                  onClick={() =>
                                    dispatch(
                                      disablePayAmountInputAction(false, 0)
                                    )
                                  }
                                >
                                  <CloseIcon />
                                </Button>
                              ) : (
                                <Button
                                  className="add_split_payment_btn_style"
                                  onClick={() =>
                                    handlePaySplitByAmount(
                                      splitPaymentAmountValue
                                    )
                                  }
                                >
                                  <CheckIcon />
                                </Button>
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="d-flex align-items-center justify-content-center paid_remaning_wrapper_style">
                          <label
                            className="pe-3"
                            datatestid="total_paid_label_in_payment_step"
                          >
                            Paid = ${+paidValue?.toFixed(2)}
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="d-flex align-items-center justify-content-center paid_remaning_wrapper_style">
                          <label
                            className="pe-3"
                            datatestid="total_remaining_label_in_payment_step"
                          >
                            Remaining = ${+remainingValue?.toFixed(2)}
                          </label>
                        </div>
                      </div>

                      <div className="col-12">
                        {creditCardErrorMessage && (
                          <Alert severity="error" className="mt-2">
                            {creditCardErrorMessage}
                          </Alert>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              <div className="d-flex my-4">
                <FormControlLabel
                  className={
                    showCashSection === "creditCardSection"
                      ? "radio_label_style active"
                      : "radio_label_style"
                  }
                  control={
                    <Radio
                      checked={
                        showCashSection === "creditCardSection" ? true : false
                      }
                      onChange={() =>
                        handleToggleBetweenCashCreditCardSections(
                          "creditCardSection"
                        )
                      }
                      datatestid="credit_card_toggle_btn_in_payment_step"
                      value={showCashSection}
                      sx={{
                        color: "#b7c0cd",
                        "&.Mui-checked": {
                          color: "#3e8ef7",
                        },
                      }}
                    />
                  }
                  label="Credit card"
                  labelPlacement="end"
                />

                <FormControlLabel
                  className={
                    showCashSection === "cashSection"
                      ? "radio_label_style active"
                      : "radio_label_style"
                  }
                  control={
                    <Radio
                      checked={showCashSection === "cashSection" ? true : false}
                      onChange={() =>
                        handleToggleBetweenCashCreditCardSections("cashSection")
                      }
                      datatestid="cash_toggle_btn_in_payment_step"
                      value={showCashSection}
                      sx={{
                        color: "#b7c0cd",
                        "&.Mui-checked": {
                          color: "#3e8ef7",
                        },
                      }}
                    />
                  }
                  label="Cash"
                  labelPlacement="end"
                />

                {showSplitPaymentSection ? null : (
                  <FormControlLabel
                    className={
                      showCashSection === "afterPaySection"
                        ? "radio_label_style active"
                        : "radio_label_style"
                    }
                    control={
                      <Radio
                        checked={
                          showCashSection === "afterPaySection" ? true : false
                        }
                        onChange={() =>
                          handleToggleBetweenCashCreditCardSections(
                            "afterPaySection"
                          )
                        }
                        datatestid="afterPay_toggle_btn_in_payment_step"
                        value={showCashSection}
                        sx={{
                          color: "#b7c0cd",
                          "&.Mui-checked": {
                            color: "#3e8ef7",
                          },
                        }}
                      />
                    }
                    label="Afterpay"
                    labelPlacement="end"
                  />
                )}
              </div>

              {showCashSection === "cashSection" && !showSplitPaymentSection ? (
                <div className="cash_section_border_style">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="custom_input_form_wrapper_style">
                        <label>Paid Amount</label>
                        <InputForm
                          type="number"
                          name="paid_amount"
                          onWheel={(e) => e.target.blur()}
                          datatestid="paid_amount_input_in_payment_step"
                          disabled={showSplitPaymentSection}
                          value={paymentFormValues?.paid_amount}
                          handleInputOnChange={handleInputOnChange}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="d-flex align-items-center change_wrapper_style">
                        <label>Change:</label>
                        <p className="ms-1">
                          ${(+paymentFormValues?.change).toFixed(2)}
                        </p>
                      </div>
                    </div>

                    {cashInputErrorMessage && (
                      <Alert severity="error" className="mt-2">
                        {cashInputErrorMessage}
                      </Alert>
                    )}
                  </div>
                </div>
              ) : showCashSection === "creditCardSection" ? (
                <>
                  {stationStripeInfo?.filter(
                    (el) => el?.id === props?.station_id
                  )[0]?.stripe_location_id !== null &&
                  stationStripeInfo?.filter(
                    (el) => el?.id === props?.station_id
                  )[0]?.terminal_type === paymentStatus.STRIPE_PAYMENT &&
                  stationStripeInfo?.filter(
                    (el) => el?.id === props?.station_id
                  )[0]?.is_terminal_connected ? (
                    <>
                      <div className="row">
                        <div className="col-12 text-center">
                          <img
                            src={require("../../Assets/img/terminal-icon.png")}
                            className="img-fluid"
                            alt="Image Not Found"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {showSplitPaymentSection ? (
                        <>
                          {disablePayAmountInput ? (
                            <div className="col-12">
                              <PaymentElement />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="row mb-3">
                          <div className="col-12">
                            <PaymentElement />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : showCashSection === "afterPaySection" &&
                !showSplitPaymentSection ? (
                <div className="row">
                  <div className="col-12 text-center">
                    <img
                      src={require("../../Assets/img/afterpay-banner.png")}
                      className="img-fluid"
                      alt="Image Not Found"
                    />
                  </div>
                </div>
              ) : null}
            </div>

            {showCashSection === "cashSection" ? (
              <div className="d-flex justify-content-end">
                <Button
                  disabled={
                    terminalLoader ||
                    afterPayLoader ||
                    isProcessing ||
                    !stripe ||
                    !elements ||
                    initiatePaymentForAfterPayIsLoading ||
                    (showSplitPaymentSection && !paymentFormValues?.pay)
                  }
                  className="pay_cash_or_credit_btn_style"
                  type="submit"
                  datatestid="pay_cash_btn_split_payment"
                  onClick={handleSubmitBtn}
                >
                  {isProcessing ||
                  !stripe ||
                  !elements ||
                  initiatePaymentForAfterPayIsLoading ||
                  terminalLoader ||
                  afterPayLoader ? (
                    <Spinner size={"sm"} style={{ color: "#fff" }} />
                  ) : (
                    "Pay cash"
                  )}
                </Button>
              </div>
            ) : showCashSection === "creditCardSection" ? (
              <div className="d-flex justify-content-end">
                <Button
                  disabled={
                    terminalLoader ||
                    afterPayLoader ||
                    isProcessing ||
                    !stripe ||
                    !elements ||
                    initiatePaymentForAfterPayIsLoading ||
                    (showSplitPaymentSection && !disablePayAmountInput)
                  }
                  className="pay_cash_or_credit_btn_style"
                  type="submit"
                  datatestid="pay_cash_btn_split_payment"
                  onClick={handleSubmitBtn}
                >
                  {isProcessing ||
                  !stripe ||
                  !elements ||
                  initiatePaymentForAfterPayIsLoading ||
                  terminalLoader ||
                  afterPayLoader ? (
                    <Spinner size={"sm"} style={{ color: "#fff" }} />
                  ) : (
                    "Pay credit"
                  )}
                </Button>
              </div>
            ) : showCashSection === "afterPaySection" &&
              !showSplitPaymentSection ? (
              <div className="d-flex justify-content-end">
                <Button
                  disabled={
                    terminalLoader ||
                    afterPayLoader ||
                    isProcessing ||
                    !stripe ||
                    !elements ||
                    initiatePaymentForAfterPayIsLoading ||
                    (showSplitPaymentSection && !paymentFormValues?.pay)
                  }
                  className="pay_cash_or_credit_btn_style"
                  type="submit"
                  datatestid="pay_cash_btn_split_payment"
                  onClick={handleSubmitBtn}
                >
                  {isProcessing ||
                  !stripe ||
                  !elements ||
                  initiatePaymentForAfterPayIsLoading ||
                  terminalLoader ||
                  afterPayLoader ? (
                    <Spinner size={"sm"} style={{ color: "#fff" }} />
                  ) : (
                    "Pay by Afterpay"
                  )}
                </Button>
              </div>
            ) : null}

            {/* Show any error or success messages */}
            {message && (
              <Alert severity="error" className="mt-2" id="payment-message">
                {message}
              </Alert>
            )}
          </>
        )}
      </div>

      {/* <div className="d-flex step_form_btns_wrapper_style">
        <Button
          disabled={!showPaymentCompleted}
          className="next_step_btn_style"
          type="submit"
          datatestid="finish_btn_in_payment_step"
          onClick={props?.handleNextStep}
        >
          Finish
        </Button>

        <Button className="back_to_step_btn_style" onClick={handleBackBtn}>
          Back
        </Button>
      </div> */}
    </>
  );
};

export default PaymentStep;
