import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "reactstrap";
import HelperFns from "../../Helpers/HelperFns";
import InputForm from "../FormComponents/InputForm";
import {
  storeCartTotalAmount,
  addSplitPaymentsItemsAction,
  storeItemDiscountAction,
  setReamainigValueAction,
  setPaidValueAction,
} from "../../Redux/Actions";
import { useMutation } from "@apollo/client";
import {
  applyDiscountMutation,
  cancelDiscountMutation,
} from "../../graphql/mutations";
import Swal from "sweetalert2";
import paymentStatus from "../../Helpers/Constants/PaymentConstants";

const CartStep = (props) => {
  const dispatch = useDispatch();

  // states from store
  const cart = useSelector((state) => state?.main?.items?.cart);
  const kitItems = useSelector((state) => state?.main?.items?.kitItems);
  const cartTotalPrice = useSelector(
    (state) => state?.main?.items?.cartTotalPrice
  );
  const validatedCart = useSelector(
    (state) => state?.main?.checkout?.validatedCart
  );

  // init useStates
  const [cancelDiscountState, setCancelDiscountState] = useState(false);
  const [discountCode, setDiscountCode] = useState({
    code: "",
  });

  // handle inputs form component change
  const handleInputOnChange = (name, value) => {
    setDiscountCode((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle apply discounbt code in cart data
  const handleApplyDiscountCode = () => {
    let data = {
      ...validatedCart,
      applyDiscountCode: discountCode?.code,
    };

    dispatch(handleApplyDiscountCodeMutation({ data }));
  };

  // Start of handle apply discount mutation
  const handleApplyDiscountCodeMutation = (cartData) => {
    applyDiscount({
      variables: {
        cart: cartData?.data,
      },
    })
      .then((response) => {
        if (response?.errors || !response?.applyDiscount?.items) {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    applyDiscount,
    {
      data: applyDiscountResponse,
      loading: applyDiscountIsLoading,
      error: applyDiscountError,
    },
  ] = useMutation(applyDiscountMutation, {
    onCompleted: (data) => {
      if (data?.applyDiscount?.items) {
        // set cancel discount state
        setCancelDiscountState(true);

        // handle the total amount caluclations function
        handleTotalCartAmountCalculations(data);

        console.log("onCompleted", data?.applyDiscount?.items);
      }
    },
  });
  // End of handle apply discount mutation

  // handle cancel discounbt code in cart data
  const handleCancelDiscountCode = () => {
    let data = {
      ...validatedCart,
      cancelDiscountCode: discountCode?.code,
    };

    dispatch(handleCancelDiscountCodeMutation({ data }));
  };

  // Start of handle apply discount mutation
  const handleCancelDiscountCodeMutation = (cartData) => {
    cancelDiscount({
      variables: {
        cart: cartData?.data,
      },
    })
      .then((response) => {
        if (response?.errors || !response?.cancelDiscount?.items) {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    cancelDiscount,
    {
      data: cancelDiscountResponse,
      loading: cancelDiscountIsLoading,
      error: cancelDiscountError,
    },
  ] = useMutation(cancelDiscountMutation, {
    onCompleted: (data) => {
      if (data?.cancelDiscount?.items) {
        // reset values
        setDiscountCode({
          code: "",
        });

        // set cancel discount state
        setCancelDiscountState(false);

        // handle the total amount caluclations function
        handleTotalCartAmountCalculations(data);

        console.log("onCompleted", data?.cancelDiscount?.items);
      }
    },
  });
  // End of handle cancel discount mutation

  // handle the total of the cart calculation
  useEffect(() => {
    // reset values
    setDiscountCode({
      code: "",
    });

    setCancelDiscountState(false);
  }, []);

  const handleTotalCartAmountCalculations = (data) => {
    if (
      !cancelDiscountState &&
      data?.applyDiscount?.items?.filter(
        (obj) => obj?.price?.discountAmount !== 0
      )?.length > 0
    ) {
      // store discount amout
      dispatch(storeItemDiscountAction(data?.applyDiscount));

      // dont hide the discount section
      props?.setHideDiscountSections(false);

      // handle init payment to get client secret for stripe
      props?.handleInitPayment(
        (
          (data?.applyDiscount?.shipping?.amount ?? 0) -
          (data?.applyDiscount?.shipping?.discountAmount ?? 0) +
          data?.applyDiscount?.items?.reduce(
            (accumulator, currentValue) =>
              accumulator +
              (currentValue?.tax?.amount - currentValue?.tax?.discountAmount) *
                currentValue?.quantity,
            0
          ) +
          data?.applyDiscount?.items?.reduce(
            (accumulator, currentValue) =>
              accumulator +
              (currentValue?.price?.amount -
                currentValue?.price?.discountAmount) *
                currentValue?.quantity,
            0
          )
        ).toFixed(2),
        paymentStatus.STRIPE_PAYMENT,
        "cartStep"
      );

      // handle store total cart amount value to use it in another components
      dispatch(
        storeCartTotalAmount(
          (
            (data?.applyDiscount?.shipping?.amount ?? 0) -
            (data?.applyDiscount?.shipping?.discountAmount ?? 0) +
            data?.applyDiscount?.items?.reduce(
              (accumulator, currentValue) =>
                accumulator +
                (currentValue?.tax?.amount -
                  currentValue?.tax?.discountAmount) *
                  currentValue?.quantity,
              0
            ) +
            data?.applyDiscount?.items?.reduce(
              (accumulator, currentValue) =>
                accumulator +
                (currentValue?.price?.amount -
                  currentValue?.price?.discountAmount) *
                  currentValue?.quantity,
              0
            )
          ).toFixed(2)
        )
      );

      // handle init split payment calcualtions
      dispatch(setPaidValueAction(0));
      dispatch(
        setReamainigValueAction(
          +(
            (data?.applyDiscount?.shipping?.amount ?? 0) -
            (data?.applyDiscount?.shipping?.discountAmount ?? 0) +
            data?.applyDiscount?.items?.reduce(
              (accumulator, currentValue) =>
                accumulator +
                (currentValue?.tax?.amount -
                  currentValue?.tax?.discountAmount) *
                  currentValue?.quantity,
              0
            ) +
            data?.applyDiscount?.items?.reduce(
              (accumulator, currentValue) =>
                accumulator +
                (currentValue?.price?.amount -
                  currentValue?.price?.discountAmount) *
                  currentValue?.quantity,
              0
            )
          ).toFixed(2)
        )
      );
    } else {
      // store discount amout
      dispatch(storeItemDiscountAction(data?.cancelDiscount));

      // handle init payment to get client secret for stripe
      props?.handleInitPayment(
        (
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalPrice,
            0
          ) +
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalSalesTaxses,
            0
          ) +
          (validatedCart?.shipping?.amount
            ? +validatedCart?.shipping?.amount
            : 0)
        )?.toFixed(2),
        paymentStatus.STRIPE_PAYMENT,
        "cartStep"
      );

      // handle store total cart amount value to use it in another components
      dispatch(
        storeCartTotalAmount(
          (
            cartTotalPrice?.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue?.itemTotalPrice,
              0
            ) +
            cartTotalPrice?.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue?.itemTotalSalesTaxses,
              0
            ) +
            (validatedCart?.shipping?.amount
              ? +validatedCart?.shipping?.amount
              : 0)
          )?.toFixed(2)
        )
      );

      // handle init split payment calcualtions
      dispatch(setPaidValueAction(0));
      dispatch(
        setReamainigValueAction(
          +(
            cartTotalPrice?.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue?.itemTotalPrice,
              0
            ) +
            cartTotalPrice?.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue?.itemTotalSalesTaxses,
              0
            ) +
            (validatedCart?.shipping?.amount
              ? +validatedCart?.shipping?.amount
              : 0)
          )?.toFixed(2)
        )
      );
    }

    // handle add split payments items when rendring the component
    dispatch(addSplitPaymentsItemsAction(cart));
  };

  return (
    <>
      <div className="row cart_step_wrapper_style">
        <h1>Order Summary</h1>

        <div className="col-12 product_divs_wrapper_style">
          {cancelDiscountState &&
          applyDiscountResponse?.applyDiscount?.items?.filter(
            (obj) => obj?.price?.discountAmount !== 0
          )?.length > 0 ? (
            <>
              {applyDiscountResponse?.applyDiscount?.items?.map((item, i) => {
                return (
                  <div className="cart_items_divs_style" key={i}>
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="d-flex">
                        <img
                          className="product_in_cart_img_style"
                          height={85}
                          src={require("../../Assets/img/no-image-found.jpg")}
                          onError={(e) => HelperFns.addDefaultSrc(e)}
                          alt="Image Not Found"
                        />

                        <div className="px-2">
                          <h2>{item?.itemName}</h2>

                          {/* <div className="cart_item_description_style">
                            <p className="mb-0">{item?.attributeOptionData}</p>
                          </div> */}
                        </div>
                      </div>

                      <div className="product_price_in_oreder_summary_step_style">
                        {item?.price?.discountAmount === 0 ? (
                          <p>
                            $
                            {(item?.price?.amount * item?.quantity)?.toFixed(2)}
                          </p>
                        ) : (
                          <>
                            <p className="mb-0">
                              $
                              {(
                                (item?.price?.amount -
                                  item?.price?.discountAmount) *
                                item?.quantity
                              )?.toFixed(2)}
                            </p>

                            <p className="discount_amount_style">
                              $
                              {(item?.price?.amount * item?.quantity)?.toFixed(
                                2
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {cart?.map((item, i) => {
                return (
                  <div className="cart_items_divs_style" key={i}>
                    <div className="d-flex align-items-start justify-content-between flex-lg-column flex-xl-row">
                      <div className="d-flex">
                        <img
                          className="product_in_cart_img_style"
                          height={85}
                          src={item?.itemDetails?.image_url}
                          onError={(e) => HelperFns.addDefaultSrc(e)}
                          alt="Image Not Found"
                        />

                        <div className="px-2">
                          <h2>{item?.itemDetails?.name}</h2>

                          {item?.itemDetails?.item_type?.toLowerCase() ===
                          "kit" ? (
                            <>
                              {item?.itemDetails?.data
                                ?.filter((el) => el?.parentCartItemId !== null)
                                ?.map((kitItem) => (
                                  <>
                                    <h2 className="kit_item_style mt-2">
                                      {kitItem?.itemName} :
                                    </h2>

                                    <div className="cart_item_description_style">
                                      <p className="mb-0">
                                        {kitItem?.attributeOptionName} x{" "}
                                        {kitItem?.quantity}
                                      </p>
                                    </div>

                                    {/* {kitItem?.firstCustomText ? (
                                <div className="cart_item_custom_text_style">
                                  <p className="mb-0 mt-1 text-break">
                                    {kitItem?.firstCustomTextLabel}:{" "}
                                    {kitItem?.firstCustomText}
                                  </p>
                                </div>
                              ) : null} */}
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <div className="cart_item_description_style">
                                <p className="mb-0">
                                  {
                                    item?.itemDetails?.data[0]
                                      ?.attributeOptionName
                                  }
                                </p>
                              </div>

                              {/* {item?.firstCustomText ? (
                          <div className="cart_item_custom_text_style">
                            <p className="mb-0 mt-1 text-break">
                              {item?.firstCustomTextLabel}:{" "}
                              {item?.firstCustomText}
                            </p>
                          </div>
                        ) : null} */}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="product_price_in_oreder_summary_step_style">
                        $
                        {(
                          +item?.itemDetails?.price *
                          +item?.itemDetails?.quantity
                        )?.toFixed(2)}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="input_Increment_decrement_wrapper_style mt-2">
                        <div className="px-0">
                          <p className="mb-0"> {item?.itemDetails?.quantity}</p>
                        </div>

                        <div className="px-2">
                          <p className="mb-0">X</p>
                        </div>

                        <div className="px-0">
                          <p className="mb-0">
                            ${+item?.itemDetails?.price?.toFixed(2)}
                          </p>
                        </div>

                        <div className="d-flex align-items-baseline px-1">
                          <p className="px-1 mb-0">=</p>
                          <p className="px-1 mb-0">
                            $
                            {(
                              +item?.itemDetails?.price *
                              +item?.itemDetails?.quantity
                            )?.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>

        <div className="col-12">
          <div className="d-flex align-items-center">
            <div className="custom_input_form_wrapper_style">
              <label>Discount Code</label>
              <InputForm
                type="text"
                name="code"
                value={discountCode.code}
                datatestid="discount_code_input"
                disabled={cancelDiscountState}
                handleInputOnChange={handleInputOnChange}
              />
            </div>

            {cancelDiscountState ? (
              <Button
                className="cancel_discount_btn_style ms-3"
                onClick={handleCancelDiscountCode}
                datatestid="cancel_discount_btn"
              >
                {cancelDiscountIsLoading ? (
                  <Spinner
                    size={"sm"}
                    style={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  "Cancel"
                )}
              </Button>
            ) : (
              <Button
                className="apply_discount_btn_style ms-3"
                onClick={handleApplyDiscountCode}
                datatestid="apply_discount_btn"
              >
                {applyDiscountIsLoading ? (
                  <Spinner
                    size={"sm"}
                    style={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  "Apply"
                )}
              </Button>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="order_summary_amount_wrapper_style">
            <div className="row align-items-center">
              <div className="col-12 col-md-10 price_wrapper_style">
                <p>Subtotal</p>
              </div>

              <div className="col-12 col-md-2 d-flex justify-content-end">
                <h3>
                  $
                  {cartTotalPrice
                    ?.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue?.itemTotalPrice,
                      0
                    )
                    ?.toFixed(2)}
                </h3>
              </div>
            </div>

            {cancelDiscountState &&
            applyDiscountResponse?.applyDiscount?.items?.filter(
              (obj) => obj?.price?.discountAmount !== 0
            )?.length > 0 ? (
              <div className="row align-items-center">
                <div className="col-12 col-md-8 price_wrapper_style">
                  <p>
                    Discount{" "}
                    <b className="discount_percentage_style">
                      {
                        applyDiscountResponse?.applyDiscount?.items?.filter(
                          (obj) =>
                            obj?.discounts[0]?.discountCalculation?.amount !==
                            "0"
                        )[0]?.discounts[0]?.discountCalculation?.amount
                      }
                      %
                    </b>
                  </p>
                </div>

                <div className="col-12 col-md-4 discount_percentage_style d-flex justify-content-end">
                  <h3>
                    $
                    {applyDiscountResponse?.applyDiscount?.items
                      ?.reduce(
                        (accumulator, currentValue) =>
                          accumulator +
                          (currentValue?.price?.amount -
                            currentValue?.price?.discountAmount) *
                            currentValue?.quantity,
                        0
                      )
                      ?.toFixed(2)}
                  </h3>
                </div>
              </div>
            ) : null}

            <div className="row align-items-center">
              <div className="col-12 col-md-8 price_wrapper_style">
                <p>Sales Tax</p>
              </div>

              {cancelDiscountState &&
              applyDiscountResponse?.applyDiscount?.items?.filter(
                (obj) => obj?.price?.discountAmount !== 0
              )?.length > 0 ? (
                <div className="col-12 col-md-4 d-flex justify-content-end discount_percentage_style">
                  <h3>
                    $
                    {applyDiscountResponse?.applyDiscount?.items
                      ?.reduce(
                        (accumulator, currentValue) =>
                          accumulator +
                          (currentValue?.tax?.amount -
                            currentValue?.tax?.discountAmount) *
                            currentValue?.quantity,
                        0
                      )
                      .toFixed(2)}
                  </h3>
                </div>
              ) : (
                <div className="col-12 col-md-4 d-flex justify-content-end">
                  <h3>
                    $
                    {cartTotalPrice
                      ?.reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue?.itemTotalSalesTaxses,
                        0
                      )
                      ?.toFixed(2)}
                  </h3>
                </div>
              )}
            </div>

            <div className="row align-items-center">
              <div className="col-12 col-md-8 price_wrapper_style">
                <p>Shipping Fees</p>
              </div>

              {cancelDiscountState &&
              applyDiscountResponse?.applyDiscount?.shipping?.discountAmount !==
                0 ? (
                <div className="col-12 col-md-4 d-flex justify-content-end discount_percentage_style">
                  <h3>
                    $
                    {(
                      (applyDiscountResponse?.applyDiscount?.shipping?.amount ??
                        0) -
                      (applyDiscountResponse?.applyDiscount?.shipping
                        ?.discountAmount ?? 0)
                    ).toFixed(2)}
                  </h3>
                </div>
              ) : (
                <div className="col-12 col-md-4 d-flex justify-content-end">
                  <h3>
                    $
                    {(validatedCart?.shipping?.amount
                      ? +validatedCart?.shipping?.amount
                      : 0
                    ).toFixed(2)}
                  </h3>
                </div>
              )}
            </div>

            <hr />

            <div className="row align-items-center quanitiy_wrapper_style">
              <div className="col-12 col-md-8">
                <h4>Total</h4>
              </div>

              <div className="col-12 col-md-4 d-flex justify-content-end">
                {cancelDiscountState &&
                applyDiscountResponse?.applyDiscount?.items?.filter(
                  (obj) => obj?.price?.discountAmount !== 0
                )?.length > 0 ? (
                  <p>
                    $
                    {(
                      (applyDiscountResponse?.applyDiscount?.shipping?.amount ??
                        0) -
                      (applyDiscountResponse?.applyDiscount?.shipping
                        ?.discountAmount ?? 0) +
                      applyDiscountResponse?.applyDiscount?.items?.reduce(
                        (accumulator, currentValue) =>
                          accumulator +
                          (currentValue?.tax?.amount -
                            currentValue?.tax?.discountAmount) *
                            currentValue?.quantity,
                        0
                      ) +
                      applyDiscountResponse?.applyDiscount?.items?.reduce(
                        (accumulator, currentValue) =>
                          accumulator +
                          (currentValue?.price?.amount -
                            currentValue?.price?.discountAmount) *
                            currentValue?.quantity,
                        0
                      )
                    ).toFixed(2)}
                  </p>
                ) : (
                  <p>
                    $
                    {(
                      cartTotalPrice?.reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue?.itemTotalPrice,
                        0
                      ) +
                      cartTotalPrice?.reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue?.itemTotalSalesTaxses,
                        0
                      ) +
                      (validatedCart?.shipping?.amount
                        ? +validatedCart?.shipping?.amount
                        : 0)
                    )?.toFixed(2)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartStep;
