import React, { useEffect, useState } from "react";
import { Typography, Backdrop, CircularProgress, Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { fetchStoreFrontsQuery } from "../../graphql/queries";
import {
  showStationModalAction,
  showConfirmPasswordModalAction,
} from "../../Redux/Actions";
import Navbar from "../../Layout/Navbar";
import StationsModal from "../../Components/StationsModal";
import CashDrawerModal from "../../Components/CashDrawerModal";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";
import HelperFns from "../../Helpers/HelperFns";
import stationStatus from "../../Helpers/Constants/StationConstants";

const StoreFrontPage = () => {
  const dispatch = useDispatch();

  // states from store
  const isStationModalVissible = useSelector(
    (state) => state?.main?.station?.stationModalActions?.isVissible
  );
  const isCashDrawerModalVissible = useSelector(
    (state) => state?.main?.station?.cashDrawerModalActions?.isVissible
  );
  const authUser = useSelector((state) => state?.main?.auth?.authUser);

  // useStates for search input
  const [searchInput, setSearchInput] = useState("");

  // handle confirm password when get back from products page
  useEffect(() => {
    window.addEventListener("popstate", () => {
      let data = {
        authEmail: authUser?.email,
        modalType: "exit_pos_session",
      };
      dispatch(showConfirmPasswordModalAction({ data }));
    });
  }, []);

  // Start of handle fetch store fronts query
  const [
    getStores,
    {
      data: storeFrontData,
      loading: isStoreFrontLoading,
      error: storeFrontError,
    },
  ] = useLazyQuery(fetchStoreFrontsQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
  });
  // End of handle fetch store fronts query

  // handle show station modal
  const handleShowStationModal = (storeId, storeName) => {
    let data = {
      storeId: storeId,
      storeName: storeName,
    };
    dispatch(showStationModalAction({ data }));
  };

  // start of handle search input
  const handleSearchInputOnChange = (e) => {
    e.preventDefault();

    // fetch store fronts variables
    getStores({
      variables: {
        store_name: searchInput,
      },
    });
  };
  // End of handle search input

  useEffect(() => {
    // fetch store fronts
    getStores({
      variables: {
        store_name: "",
      },
    });
  }, []);

  // handle store fronts according to any change in filters
  useEffect(() => {
    // fetch store fronts variables
    getStores({
      variables: {
        store_name: searchInput,
      },
    });
  }, [searchInput]);

  return (
    <>
      {/* Start of NavBar Component */}
      <Navbar
        handleSearchInputOnChange={handleSearchInputOnChange}
        setSearchInput={setSearchInput}
      />
      {/* End of NavBar Component */}

      <div className="content_style">
        {isStoreFrontLoading ? (
          <Backdrop
            sx={{
              backgroundColor: "rgba(80, 104, 136, 0.44)",
              zIndex: 1,
            }}
            open={isStoreFrontLoading}
          >
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <CircularProgress size={112} />
            </Box>
          </Backdrop>
        ) : (
          <>
            {storeFrontData?.storeFronts?.length > 0 ? (
              <div className="store_front_wrapper_style">
                <>
                  <Typography variant="h4" sx={{ pb: 4 }}>
                    Choose store front
                  </Typography>

                  <div className="store_front_cards_wrapper_style">
                    {storeFrontData?.storeFronts?.map((store) => {
                      return (
                        <Card className="store_front_card_wrapper_style">
                          <CardBody className="p-0">
                            <CardTitle className="mb-0">
                              <h4 className="mb-0">{store?.name}</h4>
                            </CardTitle>

                            <CardSubtitle className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center avatar_group_wrapper_style">
                                {store?.stations?.filter(
                                  (el) => el?.stationUser !== null
                                )?.length === 0 ? (
                                  <div className="zero_cashiers_icon_wrapper_style">
                                    <img
                                      src={require("../../Assets/img/person-circule-icon.png")}
                                    />
                                  </div>
                                ) : (
                                  <AvatarGroup max={3} spacing="small">
                                    {store?.stations
                                      ?.filter((el) => el?.stationUser !== null)
                                      ?.map((station) => (
                                        <Avatar
                                          sx={{
                                            bgcolor: HelperFns.stringToColor(
                                              station?.stationUser?.cashier
                                                ?.name
                                            ),
                                          }}
                                        >
                                          {HelperFns.getInitials(
                                            station?.stationUser?.cashier?.name
                                          )}
                                        </Avatar>
                                      ))}
                                  </AvatarGroup>
                                )}

                                <div className="cashiers_quanitiy_style">
                                  <p className="mb-0 mx-1">
                                    {`${
                                      store?.stations?.filter(
                                        (el) => el?.stationUser !== null
                                      )?.length === 0
                                        ? `(${
                                            store?.stations?.filter(
                                              (el) => el?.stationUser !== null
                                            )?.length
                                          })`
                                        : `(+${
                                            store?.stations?.filter(
                                              (el) => el?.stationUser !== null
                                            )?.length
                                          })`
                                    } Cashiers`}
                                  </p>
                                </div>
                              </div>

                              <Button
                                className="open_store_btn_style"
                                datatestid={`storeTestId-${store?.id}`}
                                onClick={() =>
                                  handleShowStationModal(store?.id, store?.name)
                                }
                              >
                                Open store
                                <img
                                  className="ms-2"
                                  src={require("../../Assets/img/arrow-icon.png")}
                                />
                              </Button>
                            </CardSubtitle>

                            <hr className="m-0" />

                            <CardText>
                              <div className="stations_status_quanitiy_style d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <img
                                    className="mx-1"
                                    src={require("../../Assets/img/persons-table-icon.png")}
                                  />
                                  <p className="mb-0">
                                    {`(${store?.stations?.length}) stations`}
                                  </p>
                                </div>

                                <div className="d-flex align-items-center">
                                  <img
                                    className="mx-1"
                                    src={require("../../Assets/img/lock-open-icon.png")}
                                  />
                                  <p className="mb-0">
                                    {`(${
                                      store?.stations?.filter(
                                        (el) =>
                                          el?.status ===
                                          stationStatus.STATION_OPENED
                                      )?.length
                                    }) Opened`}
                                  </p>
                                </div>

                                <div className="d-flex align-items-center">
                                  <img
                                    className="mx-1"
                                    src={require("../../Assets/img/lock-close-icon.png")}
                                  />
                                  <p className="mb-0">{`(${
                                    store?.stations?.filter(
                                      (el) =>
                                        el?.status ===
                                        stationStatus.STATION_CLOSED
                                    )?.length
                                  }) Closed`}</p>
                                </div>
                              </div>
                            </CardText>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </div>
                </>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center p-5 no_result_text_style">
                <div className="row">
                  <div className="col-12">There are no stores to display</div>
                </div>
              </div>
            )}
          </>
        )}

        {/* Start of Station Modal */}
        {isStationModalVissible ? <StationsModal /> : null}
        {/* End of Station Modal */}

        {/* Start of Cash Drawer Modal */}
        {isCashDrawerModalVissible ? (
          <CashDrawerModal handleFetchStoreFronts={getStores} />
        ) : null}
        {/* End of Cash Drawer Modal */}
      </div>
    </>
  );
};

export default StoreFrontPage;
