import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import moment from "moment";
import InputForm from "../FormComponents/InputForm";
import SelectForm from "../FormComponents/SelectForm";
import { Radio, FormControlLabel, Alert } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useLazyQuery, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import {
  fetchCountryStatesQuery,
  estimateRateQuery,
  verfiyAddressQuery,
} from "../../graphql/queries";
import { creatShippingAddressMutation } from "../../graphql/mutations";
import {
  addShippingAddressAction,
  clearShippingAddresses,
  addDataToValidatedCartAction,
  storeCartTotalAmount,
  addSplitPaymentsItemsAction,
  setReamainigValueAction,
  setPaidValueAction,
} from "../../Redux/Actions";
import paymentStatus from "../../Helpers/Constants/PaymentConstants";

const ShippingStep = (props) => {
  let { storeFrontId } = useParams();

  const dispatch = useDispatch();

  // states from store
  const customerFormData = useSelector(
    (state) => state?.main?.checkout?.checkoutFormData?.customerForm
  );

  const countries = useSelector((state) => state?.main?.checkout?.countries);

  const shippingAddressOptions = useSelector(
    (state) => state?.main?.checkout?.shippingAddressOptions
  );

  const validatedCart = useSelector(
    (state) => state?.main?.checkout?.validatedCart
  );

  const cart = useSelector((state) => state?.main?.items?.cart);

  const cartTotalPrice = useSelector(
    (state) => state?.main?.items?.cartTotalPrice
  );

  // init useStates of shipping form
  const [errorMessage, setErrorMessage] = useState("");
  const [newShippingAddressValues, setNewShippingAddressValues] = useState({
    address_one: "",
    address_two: "",
    country: "238",
    countryCode: "US",
    city: "",
    state: "",
    zip: "",
  });
  const [errorAddressVarificationMessage, setErrorAddressVarificationMessage] =
    useState("");
  const [radioBtnValue, setRadioBtnValue] = useState(null);

  // handle radio btn on change
  const handleRadioBtnChange = (val) => {
    setRadioBtnValue(val);
  };

  // handle inputs form component change
  const handleInputOnChange = (name, value) => {
    setNewShippingAddressValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle on select form component change
  const handleSelectOnChange = (e, selectName) => {
    setNewShippingAddressValues((prevState) => ({
      ...prevState,
      [selectName]: e?.value,
    }));
  };

  const handleTotalCartAmountCalculations = (shippingAmount) => {
    // handle init payment to get client secret for stripe
    props?.handleInitPayment(
      (
        cartTotalPrice?.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue?.itemTotalPrice,
          0
        ) +
        cartTotalPrice?.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue?.itemTotalSalesTaxses,
          0
        ) +
        (shippingAmount ? shippingAmount : 0)
      )?.toFixed(2),
      paymentStatus.STRIPE_PAYMENT,
      "shippingStep"
    );

    // handle store total cart amount value to use it in another components
    dispatch(
      storeCartTotalAmount(
        (
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalPrice,
            0
          ) +
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalSalesTaxses,
            0
          ) +
          (shippingAmount ? shippingAmount : 0)
        )?.toFixed(2)
      )
    );

    // handle init split payment calcualtions
    dispatch(setPaidValueAction(0));
    dispatch(
      setReamainigValueAction(
        +(
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalPrice,
            0
          ) +
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalSalesTaxses,
            0
          ) +
          (shippingAmount ? shippingAmount : 0)
        )?.toFixed(2)
      )
    );

    // handle add split payments items when rendring the component
    dispatch(addSplitPaymentsItemsAction(cart));
  };

  // handle back btn
  const handleBackBtn = () => {
    // clear shipping address
    dispatch(clearShippingAddresses());

    setErrorMessage("");

    // handle form step
    props?.handleBackToStep();
  };

  // Start of handle fetch country states query
  const [
    fetchCountryStates,
    {
      data: countryStatesData,
      loading: countryStatesIsLoading,
      error: countryStatesResponseError,
    },
  ] = useLazyQuery(fetchCountryStatesQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
  });
  // End of handle fetch country states query

  // handle on change in country and fetch states if there is any
  useEffect(() => {
    fetchCountryStates({
      variables: {
        country_id: newShippingAddressValues?.country
          ? +newShippingAddressValues?.country
          : 238,
      },
    });
  }, [newShippingAddressValues?.country]);

  // Start of handle calculate estimate rate query
  const [
    calcualteEstimateRate,
    {
      data: estimateRateData,
      loading: estimateRateIsLoading,
      error: estimateRateResponseError,
    },
  ] = useLazyQuery(estimateRateQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
  });
  // End of handle calculate estimate rate query

  const handleSubmitBtn = () => {
    setErrorMessage("");

    // handle get estimate shipping rate before going to the next step
    if (
      estimateRateData?.getEstimateRate?.status === "success" ||
      estimateRateData?.getEstimateRate?.status === "default"
    ) {
      // handle add data to validated cart
      let data = {
        ...validatedCart,
        customerId: +customerFormData?.id,
        shippingAddressId: +radioBtnValue,
        shipping: {
          carrierId:
            estimateRateData?.getEstimateRate?.shipping_rate?.carrier_id,
          serviceMethod:
            estimateRateData?.getEstimateRate?.shipping_rate?.service_type,
          amount:
            +estimateRateData?.getEstimateRate?.shipping_rate?.shipping_amount
              ?.amount,
          discountAmount: 0,
        },
      };

      dispatch(addDataToValidatedCartAction({ data }));

      handleTotalCartAmountCalculations(
        +estimateRateData?.getEstimateRate?.shipping_rate?.shipping_amount
          ?.amount
      );
    } else {
      calcualteEstimateRate({
        variables: {
          station_id: +storeFrontId,
          shipping_address_id: +radioBtnValue,
          weight: {
            value: `${cart?.reduce(
              (accumulator, currentValue) =>
                +accumulator + +currentValue?.itemDetails?.weight,
              0
            )}`,
            unit: cart?.[0]?.itemDetails?.weight_unit,
          },
        },
      });
    }
  };

  // Start of handle create new shipping address mutation
  const handleCreateNewShippingAddress = (
    customerFormData,
    newShippingAddressValues
  ) => {
    createNewShippingAddress({
      variables: {
        input: {
          entity_id: +customerFormData?.id ?? "674",
          first_name: customerFormData?.first_name,
          last_name: customerFormData?.last_name,
          address:
            newShippingAddressValues?.address_one +
            " " +
            newShippingAddressValues?.address_two,
          city: newShippingAddressValues?.city,
          state: newShippingAddressValues?.state
            ? newShippingAddressValues?.state
            : "",
          zip: +newShippingAddressValues?.zip?.replace("-", ""),
        },
      },
    })
      .then((response) => {
        if (
          response?.errors?.length > 0 ||
          response?.createShippingAddress?.status === 0
        ) {
          // handle error message
          setErrorMessage(response?.errors[0]?.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error?.message ?? "Something went wrong");
        console.log("catch", { error });
      });
  };

  const [
    createNewShippingAddress,
    {
      data: createNewShippingAddressResponse,
      loading: createNewShippingAddressIsLoading,
      error: createNewShippingAddressError,
    },
  ] = useMutation(creatShippingAddressMutation, {
    onCompleted: (data) => {
      if (data?.createShippingAddress?.status === 1) {
        // reset add shipping address form and its error
        setErrorMessage("");
        setErrorAddressVarificationMessage("");
        setNewShippingAddressValues({
          address_one: "",
          address_two: "",
          country: "238",
          countryCode: "US",
          city: "",
          state: "",
          zip: "",
        });

        // select created address by default
        handleRadioBtnChange(data?.createShippingAddress?.shippingAddress?.id);

        // add new shipping address to shipping Adddress options
        dispatch(
          addShippingAddressAction(data?.createShippingAddress?.shippingAddress)
        );
      }
    },
  });
  // End of handle create new shipping address mutation

  // Start of handle verfiy address query
  const [
    verfiyAddress,
    {
      data: addressVerficationData,
      loading: addressVerficationIsLoading,
      error: addressVerficationResponseError,
    },
  ] = useLazyQuery(verfiyAddressQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      // handle verfiy address before creating new customer
      if (
        data?.verifyAddress?.status === "verified" ||
        data?.verifyAddress?.status === "error"
      ) {
        // handle create new shipping address
        handleCreateNewShippingAddress(
          customerFormData,
          newShippingAddressValues
        );
      } else {
        // handle zip code error only
        if (
          data?.verifyAddress?.messages[0]?.detail_code ===
          "invalid_postal_code"
        ) {
          // handle error message
          setErrorAddressVarificationMessage(
            data?.verifyAddress?.messages[0]?.message
          );
        }
      }

      console.log("onCompleted", data);
    },
  });
  // End of handle verfiy address query

  // handle verfiy address
  const handleVerfiyAddress = () => {
    if (
      newShippingAddressValues?.first_name === "" ||
      newShippingAddressValues?.last_name === "" ||
      newShippingAddressValues?.address_one === "" ||
      newShippingAddressValues?.zip === "" ||
      newShippingAddressValues?.countryCode === "" ||
      newShippingAddressValues?.city === "" ||
      newShippingAddressValues?.state === ""
    ) {
      setErrorAddressVarificationMessage(
        "Please fill the required shipping address fields to verify the given address."
      );
    } else {
      verfiyAddress({
        variables: {
          input: {
            name:
              newShippingAddressValues?.first_name +
              " " +
              newShippingAddressValues?.last_name,
            address:
              newShippingAddressValues?.address_one +
              " " +
              newShippingAddressValues?.address_two,
            zip: newShippingAddressValues?.zip,
            countryCode: newShippingAddressValues?.countryCode,
            city: newShippingAddressValues?.city,
            state: newShippingAddressValues?.state,
          },
          store_front_id: +storeFrontId,
        },
      });
    }
  };

  // handle on change in shipping id to get estimat rate
  useEffect(() => {
    if (radioBtnValue !== null) {
      calcualteEstimateRate({
        variables: {
          station_id: +storeFrontId,
          shipping_address_id: +radioBtnValue,
          weight: {
            value: `${cart?.reduce(
              (accumulator, currentValue) =>
                +accumulator + +currentValue?.itemDetails?.weight,
              0
            )}`,
            unit: cart?.[0]?.itemDetails?.weight_unit,
          },
        },
      });
    }
  }, [radioBtnValue]);

  useEffect(() => {
    // reset all useStates of this form
    setErrorMessage("");
    setErrorAddressVarificationMessage("");
    setNewShippingAddressValues({
      address_one: "",
      address_two: "",
      country: "238",
      countryCode: "US",
      city: "",
      state: "",
      zip: "",
    });
    return () => {};
  }, []);

  return (
    <>
      <div className="step_form_wrapper_style shipping_form_wrapper_style">
        <div className="row">
          <div className="col-12">
            {/* <div
              className={
                customerFormData?.id === "674" ? "row mb-0" : "row mb-3"
              }
            >
              <div className="col-12">
                <div className="d-flex">
                  <b>
                    {customerFormData?.first_name} {customerFormData?.last_name}
                  </b>
                  <p className="mx-3">{customerFormData?.phone}</p>
                  <p>{customerFormData?.email}</p>
                </div>
              </div>
            </div> */}

            <div className="row">
              {shippingAddressOptions?.length
                ? shippingAddressOptions?.map((item, i) => {
                    return (
                      <div className="col-12 d-flex">
                        <FormControlLabel
                          className="radio_label_style"
                          control={
                            <Radio
                              checked={
                                radioBtnValue === item?.id ? true : false
                              }
                              onChange={() => handleRadioBtnChange(item?.id)}
                              datatestid={`exiting-shipping-address-index-${i}`}
                              value={radioBtnValue}
                              sx={{
                                color: "#b7c0cd",
                                "&.Mui-checked": {
                                  color: "#3e8ef7",
                                },
                              }}
                            />
                          }
                        />

                        <div
                          onClick={() => handleRadioBtnChange(item?.id)}
                          className={
                            radioBtnValue === item?.id
                              ? "d-flex align-items-baseline exiting_address_style w-100 active"
                              : "d-flex align-items-baseline exiting_address_style w-100"
                          }
                        >
                          <h1 className="me-1">{`Address ${i + 1}:`}</h1>

                          <p>
                            {item?.address ? `${item?.address},` : null}{" "}
                            {item?.city ? `${item?.city},` : null}{" "}
                            {item?.state ? `${item?.state},` : null}{" "}
                            {item?.zip ? `${item?.zip},` : null}{" "}
                            {item?.country ? "United States of America" : null}{" "}
                            {item?.phone ? `, ${item?.phone}` : null}
                          </p>
                        </div>
                      </div>
                    );
                  })
                : null}

              <div className="col-12">
                <div
                  className="address_btn_style"
                  onClick={() => handleRadioBtnChange(null)}
                  datatestid="add_new_shipping_address_radio_btn"
                >
                  <img
                    className="me-2"
                    src={require("../../Assets/img/plus-icon.png")}
                  />
                  New Shipping Address
                </div>

                {radioBtnValue === null ? (
                  <div className="add_new_shipping_address_wrapper_style ps-4">
                    <div className="row mb-4">
                      <div className="col-12">
                        <div className="custom_input_form_wrapper_style">
                          <label>Address Line 1*</label>
                          <InputForm
                            type="text"
                            name="address_one"
                            placeholder="Entre address"
                            datatestid="address_one_shipping_step_input"
                            value={newShippingAddressValues?.address_one}
                            handleInputOnChange={handleInputOnChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12">
                        <div className="custom_input_form_wrapper_style">
                          <label>Address Line 2</label>
                          <InputForm
                            type="text"
                            name="address_two"
                            placeholder="Entre address"
                            datatestid="address_two_shipping_step_input"
                            value={newShippingAddressValues?.address_two}
                            handleInputOnChange={handleInputOnChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-xl-4 mb-4 mb-xl-0">
                        <div className="custom_input_form_wrapper_style">
                          <label>City*</label>
                          <InputForm
                            type="text"
                            name="city"
                            placeholder="Entre city"
                            datatestid="city_shipping_step_input"
                            value={newShippingAddressValues?.city}
                            handleInputOnChange={handleInputOnChange}
                          />
                        </div>
                      </div>

                      {countryStatesData?.getStates?.length > 0 ? (
                        <div className="col-12 col-lg-6 col-xl-4">
                          <div className="custom_select_wrapper_style">
                            <label>State*</label>

                            <SelectForm
                              name="state"
                              selectOptions={countryStatesData?.getStates?.map(
                                ({ name }) => ({
                                  label: name,
                                  value: name,
                                })
                              )}
                              handleSelectFormChange={(e) =>
                                handleSelectOnChange(e, "state")
                              }
                              placeholder="Select state"
                              selectContainerDataTestId="state_container_filter_data_test_id_in_shipping_step"
                              isClearable
                            />
                          </div>
                        </div>
                      ) : null}

                      <div className="col-12 col-lg-6 col-xl-4">
                        <div className="custom_input_form_wrapper_style">
                          <label>Zip*</label>
                          <InputForm
                            type="text"
                            name="zip"
                            datatestid="zip_shipping_step_input"
                            placeholder="Entre Zip code"
                            value={newShippingAddressValues?.zip}
                            handleInputOnChange={handleInputOnChange}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="row mb-4">
                      <div className="col-12 col-md-6">
                        <label>Country*</label>
                        <SelectForm
                          name="country"
                          selectOptions={{
                            label: "United States of America",
                            value: "238",
                            code: "US",
                          }}
                          handleSelectFormChange={(e) =>
                            handleSelectOnChange(e, "country")
                          }
                          isDisabled={true}
                          isLoading={countryStatesIsLoading}
                          selectContainerDataTestId="country_container_filter_data_test_id_in_shipping_step"
                          defaultValue={{
                            label: "United States of America",
                            value: "238",
                            code: "US",
                          }}
                        />
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-12 d-flex justify-content-end">
                        <Button
                          className="add_new_addresss_btn_style px-4"
                          onClick={handleVerfiyAddress}
                          datatestid="add_shipping_address_btn"
                          disabled={
                            addressVerficationIsLoading ||
                            createNewShippingAddressIsLoading
                          }
                        >
                          {addressVerficationIsLoading ||
                          createNewShippingAddressIsLoading ? (
                            <Spinner
                              size={"sm"}
                              style={{
                                color: "#3e8ef7",
                              }}
                            />
                          ) : (
                            "Done"
                          )}
                        </Button>
                      </div>
                    </div>

                    {/* (Start) Error address verfication Message */}
                    {errorAddressVarificationMessage && (
                      <Alert severity="error" className="mt-2">
                        {errorAddressVarificationMessage}
                      </Alert>
                    )}
                    {/* (End) Error address verfication Message */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="col-12 col-md-6 border-left ps-4">
            <div className="row mb-4">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <label>Carrier Name</label>
                  <h5 className="mb-0">
                    {estimateRateData?.getEstimateRate?.shipping_rate
                      ?.carrier_nickname
                      ? estimateRateData?.getEstimateRate?.shipping_rate
                          ?.carrier_nickname
                      : "------"}
                  </h5>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <label>Service Type</label>
                  <h5 className="mb-0">
                    {estimateRateData?.getEstimateRate?.shipping_rate
                      ?.service_type
                      ? estimateRateData?.getEstimateRate?.shipping_rate
                          ?.service_type
                      : "------"}
                  </h5>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <label>Package Type</label>
                  <h5 className="mb-0">
                    {estimateRateData?.getEstimateRate?.shipping_rate
                      ?.package_type
                      ? estimateRateData?.getEstimateRate?.shipping_rate
                          ?.package_type
                      : "------"}
                  </h5>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <label>Carrier Delivery Days</label>
                  <h5 className="mb-0">
                    {estimateRateData?.getEstimateRate?.shipping_rate
                      ?.carrier_delivery_days
                      ? estimateRateData?.getEstimateRate?.shipping_rate
                          ?.carrier_delivery_days
                      : "------"}
                  </h5>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <label>Estimated Delivery Date</label>
                  <h5 className="mb-0">
                    {estimateRateData?.getEstimateRate?.shipping_rate
                      ?.estimated_delivery_date
                      ? moment(
                          estimateRateData?.getEstimateRate?.shipping_rate
                            ?.estimated_delivery_date
                        ).format("MM - DD - YYYY")
                      : "------"}
                  </h5>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <label>Shipping Cost</label>
                  <h5 className="mb-0 text-uppercase">
                    {estimateRateData?.getEstimateRate?.shipping_rate
                      ?.shipping_amount?.amount
                      ? estimateRateData?.getEstimateRate?.shipping_rate
                          ?.shipping_amount?.amount
                      : "------"}{" "}
                    {
                      estimateRateData?.getEstimateRate?.shipping_rate
                        ?.shipping_amount?.currency
                    }
                  </h5>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {/* (Start) Error Message */}
        {errorMessage && (
          <Alert severity="error" className="mt-2">
            {errorMessage}
          </Alert>
        )}
        {/* (End) Error Message */}
      </div>

      <div className="d-flex step_form_btns_wrapper_style">
        <Button
          className="next_step_btn_style"
          type="submit"
          onClick={handleSubmitBtn}
          datatestid="continue_from_shipping_step_to_next_step_btn"
          disabled={
            props?.initpaymentIsLoading ||
            estimateRateIsLoading ||
            radioBtnValue === null
              ? true
              : false
          }
        >
          {props?.initpaymentIsLoading || estimateRateIsLoading ? (
            <Spinner size={"sm"} style={{ color: "#fff" }} />
          ) : (
            "Continue"
          )}
        </Button>

        <Button className="back_to_step_btn_style" onClick={handleBackBtn}>
          Back
        </Button>
      </div>
    </>
  );
};

export default ShippingStep;
