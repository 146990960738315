import React from "react";
import Clock from "react-live-clock";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import HelperFns from "../Helpers/HelperFns";
import { logoutAction, showConfirmPasswordModalAction } from "../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import ConfirmPasswordModal from "../Components/ConfirmPasswordModal";

const Navbar = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // state from store
  const authUser = useSelector((state) => state?.main?.auth?.authUser);
  const isConfirmPasswordModalVissible = useSelector(
    (state) =>
      state?.main?.auth?.confirmPasswordModalOutOfSessionActions?.isVissible
  );

  // handle logout
  const handleLogout = () => {
    // navigate to login page
    navigate(`/`);

    dispatch(logoutAction());
  };

  // handle exit items list and show confirm password modal
  const handleExitItemsList = () => {
    let data = {
      authEmail: authUser?.email,
      modalType: "exit_items_list",
    };
    dispatch(showConfirmPasswordModalAction({ data }));
  };

  // handle open drawer manually and confirm password
  const handleOpenDrawerManually = () => {
    let data = {
      authEmail: authUser?.email,
      modalType: "open_drawer_manually",
    };
    dispatch(showConfirmPasswordModalAction({ data }));
  };

  // handle navigate to return items page
  const handleNavigateToReturnItems = () => {
    navigate(`/return-items`);
  };

  return (
    <>
      <div className="row align-items-center navbar_wrapper_style">
        <div className="d-flex justify-content-between time_need_help_wrapper_style">
          <div className="d-flex align-items-end">
            <div className="mx-2">
              <img src={require("../Assets/img/clock-icon.png")} />
            </div>

            <div>
              <h2 className="mb-0">
                <Clock format={"dddd hh:mm A YYYY"} ticking={true} />
              </h2>
            </div>
          </div>

          <div className="d-flex align-items-end">
            <div className="mx-2">
              <img src={require("../Assets/img/need-help-icon.png")} />
            </div>

            <div>
              <h2 className="mb-0">Need Help</h2>
            </div>
          </div>
        </div>

        <div className="row align-items-center logo_search_wrapper_style">
          <div className="col-12 col-md-4 mb-sm-3">
            <img
              src={require("../Assets/img/logo.png")}
              alt="logo"
              className="logo_style img-fluid px-2"
            />
          </div>

          <div className="col-12 col-md-4 text-end">
            {props?.itemsListIsOpened ? null : (
              <div className="row search_store_fronts_by_name_wrapper_style">
                <div className="col-sm-8 col-md-9 col-xl-10">
                  <form onSubmit={props?.handleSearchInputOnChange}>
                    <input
                      className="search_by_store_front_name_input_style"
                      placeholder="Search by keywords..."
                      datatestid="search_by_store_front_name_input_filter"
                      onChange={(e) => {
                        props?.setSearchInput(e?.target?.value);
                      }}
                    />
                  </form>
                </div>

                <div className="col-sm-4 col-md-3 col-xl-2">
                  <img
                    src={require("../Assets/img/search-icon.png")}
                    className="cursor-pointer"
                    onClick={props?.handleSearchInputOnChange}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-12 col-md-4 d-flex align-items-center justify-content-end px-0">
            {/* <Badge color="info" variant="dot">
                    <div className="navbar_icon_wrapper_style">
                      <img
                        src={require("../Assets/img/notification-icon.png")}
                      />
                    </div>
                  </Badge>

                  <div className="navbar_icon_wrapper_style mx-3">
                    <img src={require("../Assets/img/email-icon.png")} />
                  </div> */}

            {props?.itemsListIsOpened ? (
              <div className="open_drawer_return_items_btns_wrapper_style me-4">
                <Button
                  className="open_drawer_btn_style"
                  onClick={handleOpenDrawerManually}
                >
                  Drawer Printing
                  <img
                    className="ms-2"
                    src={require("../Assets/img/print-icon.png")}
                  />
                </Button>

                {props?.inReturnItemsPage ? null : (
                  <Button
                    className="return_items_btn_style mx-3"
                    onClick={handleNavigateToReturnItems}
                  >
                    Return Items
                  </Button>
                )}
              </div>
            ) : null}

            <Avatar
              sx={{
                bgcolor: HelperFns.stringToColor(
                  authUser?.contactName ? authUser?.contactName : authUser?.name
                ),
              }}
            >
              {HelperFns.getInitials(
                authUser?.contactName ? authUser?.contactName : authUser?.name
              )}
            </Avatar>

            {props?.itemsListIsOpened ? (
              <div
                className="exit_btn_style cursor-pointer ms-4"
                onClick={handleExitItemsList}
              >
                Exit
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* Start of confirm password modal */}
      {isConfirmPasswordModalVissible ? <ConfirmPasswordModal /> : null}
      {/* End of confirm password modal */}
    </>
  );
};

export default Navbar;
