import React, { useEffect, useState, useCallback } from "react";
import { PaginationItem, Backdrop, CircularProgress, Box } from "@mui/material";
import Cart from "../../Components/Cart";
import { useDispatch, useSelector } from "react-redux";
import {
  showItemCombinationAction,
  showConfirmPasswordModalAction,
  showSalesPersonIdModalAction,
  showStripeTerminalDisconnectedModalAction,
  hideStripeTerminalDisconnectedModalAction,
  storeStripeTerminalInfoAction,
  hideSalesPersonIdModalAction,
} from "../../Redux/Actions";
import { createTerminalTokenMutation } from "../../graphql/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { fetchItemsQuery } from "../../graphql/queries";
import Pagination from "@mui/material/Pagination";
import { useLocation, useBeforeUnload, useNavigate } from "react-router-dom";
import HelperFns from "../../Helpers/HelperFns";
import Navbar from "../../Layout/Navbar";
import QueudOrders from "../../Components/QueudOrders";
import FilterMenu from "../../Components/FilterMenu";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Badge,
  Button,
} from "reactstrap";
import SalesPersonIdModal from "../../Components/SalesPersonIdModal";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import ConfirmPasswordModal from "../../Components/ConfirmPasswordModal";
import OpenDrawerManuallyModal from "../../Components/OpenDrawerManuallyModal";
import paymentStatus from "../../Helpers/Constants/PaymentConstants";
import { loadStripeTerminal } from "@stripe/terminal-js";
import StripeTerminalDisconnectedModal from "../../Components/StripeTerminalDisconnectedModal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Calculator from "../../Components/Calculator";

const Products = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  // states from store
  const isValidateSalesPersonIdModalVissible = useSelector(
    (state) => state?.main?.items?.salesPersonIdModalActions?.isVissible
  );
  const isConfirmPasswordModalVissible = useSelector(
    (state) => state?.main?.auth?.confirmPasswordModalActions?.isVissible
  );
  const isStripeTerminalDisconnectedModalVissible = useSelector(
    (state) =>
      state?.main?.station?.stripeTerminalDisconnectedModalActions?.isVissible
  );
  const isOpenDrawerManuallyModalVissible = useSelector(
    (state) => state?.main?.items?.openDrawerManuallyModalActions?.isVissible
  );
  const authUser = useSelector((state) => state?.main?.auth?.authUser);
  const stationInfo = useSelector((state) => state?.main?.station?.stationInfo);
  const stationStripeInfo = useSelector(
    (state) => state?.main?.station?.stationStripeInfo
  );

  // useStates for stripe terminal
  const [terminalLoader, setTerminalLoader] = useState(false);

  // useStates for list pagination
  const [page, setPage] = useState(1);

  // useStates for search input
  const [searchInput, setSearchInput] = useState("");

  // useState for show/hide filters section
  const [showFiltersSection, setShowFiltersSection] = useState(false);

  // useStates for filters input form component
  const [manafactureValue, setManafactureValue] = useState("");
  const [brandValue, setBrandValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [barcodeValue, setBarcodeValue] = useState("");

  // useStates for filter select options
  const [manafactureArrayOptions, setManafactureArrayOptions] = useState([]);
  const [brandArrayOptions, setBrandArrayOptions] = useState([]);
  const [categoryArrayOptions, setCategoryArrayOptions] = useState([]);

  // save it off before users navigate away and when user change url
  useBeforeUnload(
    useCallback(() => {
      let data = {
        authEmail: authUser?.email,
        modalType: "exit_pos_session",
      };
      dispatch(showConfirmPasswordModalAction({ data }));
    }, [location])
  );

  // Start of handle fetch items query
  const [
    getItems,
    { data: itemsData, loading: itemsLoading, error: itemsResponseError },
  ] = useLazyQuery(fetchItemsQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      // set filter select array options
      setManafactureArrayOptions(data?.getManufacturers);
      setBrandArrayOptions(data?.getBrands);
      setCategoryArrayOptions(data?.getItemCategories);

      console.log("onCompleted", data);
    },
  });
  // End of handle fetch items query

  // start of handle search input
  const handleSearchInputOnChange = (e) => {
    e.preventDefault();

    // fetch items variables
    getItems({
      variables: {
        page: page,
        station_id: +stationInfo?.stationId,
        store_id: +stationInfo?.storeId,
        item_name: searchInput,
        barcode: barcodeValue,
        category_id: +categoryValue,
        brand_id: +brandValue,
        manufacturer_id: +manafactureValue,
      },
    });
  };
  // End of handle search input

  // handle of list pagination
  const handleListPagination = (item) => {
    // fetch items with the new page number
    getItems({
      variables: {
        page: item?.page,
        store_id: +stationInfo?.storeId,
        station_id: +stationInfo?.stationId,
        item_name: searchInput,
        barcode: barcodeValue,
        category_id: +categoryValue,
        brand_id: +brandValue,
        manufacturer_id: +manafactureValue,
      },
    });
  };

  // handle show items combination
  const handleShowItemsCombination = (
    itemId,
    itemType,
    itemName,
    imageUrl,
    price,
    IsBackorder,
    weight,
    weightUnit,
    quantity,
    description
  ) => {
    let data = {
      item_id: itemId,
      storeId: stationInfo?.storeId,
      stationId: stationInfo?.stationId,
      storeName: stationInfo?.storeName,
      stationName: stationInfo?.stationName,
      item_type: itemType,
      name: itemName,
      image_url: imageUrl,
      price,
      is_backorder: IsBackorder,
      weight,
      weight_unit: weightUnit,
      quantity,
      description,
    };
    dispatch(showItemCombinationAction({ data }));

    // navigate to products detials page
    navigate(`/product-details`);
  };

  // handle toggle filter section
  const handleToggleFilterSection = () => {
    setManafactureValue("");
    setBrandValue("");
    setCategoryValue("");
    setShowFiltersSection(!showFiltersSection);
  };

  // handle on select form component change
  const handleSelectOnChange = (e, selectName) => {
    if (selectName === "manufacturer_id") {
      setManafactureValue(e?.value);
    }
    if (selectName === "brand_id") {
      setBrandValue(e?.value);
    }
    if (selectName === "category_id") {
      setCategoryValue(e?.value);
    }
  };

  // handle show sales person id modal
  const handleShowSalesPersonIdModal = () => {
    let data = {};
    dispatch(showSalesPersonIdModalAction({ data }));
  };

  // handle show stripe terminal discconnected modal
  const handleShowStripeTerminalDisconnectedModal = (message) => {
    let data = { modalMessage: message };
    dispatch(showStripeTerminalDisconnectedModalAction({ data }));
  };

  // to generate connection token for terminal
  const handleGenerateTerminalTokenMutation = () => {
    createTerminalToken({
      variables: {
        method: paymentStatus.STRIPE_PAYMENT,
        stationId: +stationInfo?.stationId,
      },
    })
      .then((response) => {
        if (response?.errors || !response?.createTerminalToken?.secret) {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    createTerminalToken,
    {
      data: connectTerminalTokenResponse,
      loading: connectTerminalTokenIsLoading,
      error: connectTerminalTokenError,
    },
  ] = useMutation(createTerminalTokenMutation, {
    onCompleted: (data) => {
      if (data?.createTerminalToken?.secret) {
        console.log("onCompleted", data?.createTerminalToken?.secret);
      }
    },
  });

  // handle onFetch connection token returen secret
  const onFetchConnectionToken = () => {
    return connectTerminalTokenResponse?.createTerminalToken?.secret ?? "";
  };

  // handle when there is unexpected disconnection of the reader
  const onUnexpectedReaderDisconnect = () => {
    // show stripe terminal disconnected modal
    handleShowStripeTerminalDisconnectedModal(
      "Reader is disconnected. Please attempt to reconnect."
    );
  };

  // handle init terminal sdk
  const handleInitStripeTerminal = async () => {
    setTerminalLoader(true);

    const StripeTerminal = await loadStripeTerminal();

    const terminal = StripeTerminal.create({
      onFetchConnectionToken,
      onUnexpectedReaderDisconnect,
    });

    // for testing purposes
    const config = stationStripeInfo?.filter(
      (el) => el?.id === stationInfo?.stationId
    )[0]?.test_terminal
      ? {
          simulated: true,
        }
      : {
          simulated: false,
          location: stationStripeInfo?.filter(
            (el) => el?.id === stationInfo?.stationId
          )[0]?.stripe_location_id,
        };

    const discoverResult = await terminal.discoverReaders(config);
    if (discoverResult?.error) {
      setTerminalLoader(false);

      // show stripe terminal disconnected modal
      handleShowStripeTerminalDisconnectedModal(discoverResult?.error?.message);

      console.log("Failed to discover: ", discoverResult?.error);
    } else if (discoverResult?.discoveredReaders?.length === 0) {
      setTerminalLoader(false);

      // show stripe terminal disconnected modal
      handleShowStripeTerminalDisconnectedModal("No available readers.");

      console.log("No available readers.");
    } else {
      // Just select the first reader here.
      const selectedReader = discoverResult?.discoveredReaders[0];

      const connectResult = await terminal.connectReader(selectedReader);
      if (connectResult.error) {
        setTerminalLoader(false);

        // show stripe terminal disconnected modal
        handleShowStripeTerminalDisconnectedModal(
          connectResult?.error?.message
        );

        console.log("Failed to connect: ", connectResult.error);
      } else {
        console.log("Connected to reader: ", connectResult);

        // close sales person modal
        dispatch(hideSalesPersonIdModalAction());

        // close stripe terinal disconnected modal
        dispatch(hideStripeTerminalDisconnectedModalAction());

        // trigger toaster
        toast.success(
          `Successfully connected to ${connectResult?.reader?.label}.`,
          {
            style: {
              border: "1px solid #27b40c",
              padding: "10px",
              color: "#263238",
            },
            iconTheme: {
              primary: "#27b40c",
              secondary: "#fff",
            },
          }
        );

        // store terminal info
        let data = { terminal: terminal, connectResult: connectResult };

        dispatch(storeStripeTerminalInfoAction({ data }));
      }
    }
  };

  // handle fetch items according to any change in filters
  useEffect(() => {
    getItems({
      variables: {
        page: page,
        station_id: +stationInfo?.stationId,
        store_id: +stationInfo?.storeId,
        item_name: searchInput,
        barcode: barcodeValue,
        category_id: +categoryValue,
        brand_id: +brandValue,
        manufacturer_id: +manafactureValue,
      },
    });
  }, [categoryValue, brandValue, manafactureValue, searchInput, barcodeValue]);

  useEffect(() => {
    // to handle init terminal for stripe
    if (
      stationStripeInfo?.filter((el) => el?.id === stationInfo?.stationId)[0]
        ?.stripe_location_id !== null &&
      stationStripeInfo?.filter((el) => el?.id === stationInfo?.stationId)[0]
        ?.terminal_type === paymentStatus.STRIPE_PAYMENT &&
      stationStripeInfo?.filter((el) => el?.id === stationInfo?.stationId)[0]
        ?.is_terminal_connected
    ) {
      // to generate connection token
      handleGenerateTerminalTokenMutation();
    }

    // to check if i am getting back from product detials page
    if (location?.state?.from === "products-details") {
      // close sales person modal
      dispatch(hideSalesPersonIdModalAction());
    } else {
      // show sales person id
      handleShowSalesPersonIdModal();
    }

    // fetch items
    getItems({
      variables: {
        page: page,
        station_id: +stationInfo?.stationId,
        store_id: +stationInfo?.storeId,
        item_name: searchInput,
        barcode: barcodeValue,
        category_id: +categoryValue,
        brand_id: +brandValue,
        manufacturer_id: +manafactureValue,
      },
    });
  }, []);

  return (
    <>
      {/* Start of NavBar Component */}
      <Navbar itemsListIsOpened={true} />
      {/* End of NavBar Component */}

      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className="station_store_name_wrapper_style"
      >
        <Link underline="hover" href="/store-fronts">
          Home
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stores
        </Link>
        <Link underline="hover" href="/store-fronts">
          {stationInfo?.storeName} Retail POS
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stations
        </Link>
        <Link color="text.primary">{stationInfo?.stationName}</Link>
      </Breadcrumbs>

      {/* Start of Queud Orders Component  */}
      {/* <QueudOrders /> */}
      {/* End of Queud Orders Component  */}

      <div className="product_list_station_name_style">
        {stationInfo?.stationName}
      </div>

      {/* Start of Filter Menu Component  */}
      <FilterMenu
        setSearchInput={setSearchInput}
        setBarcodeValue={setBarcodeValue}
        handleSearchInputOnChange={handleSearchInputOnChange}
        showFiltersSection={showFiltersSection}
        handleSelectOnChange={handleSelectOnChange}
        handleToggleFilterSection={handleToggleFilterSection}
        manafactureArrayOptions={manafactureArrayOptions}
        brandArrayOptions={brandArrayOptions}
        categoryArrayOptions={categoryArrayOptions}
      />
      {/* End of Filter Menu Component  */}

      <div className="content_style">
        {itemsLoading ? (
          <Backdrop
            sx={{
              backgroundColor: "rgba(80, 104, 136, 0.44)",
              zIndex: 1,
            }}
            open={itemsLoading}
          >
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <CircularProgress size={112} />
            </Box>
          </Backdrop>
        ) : (
          <>
            {itemsData?.getItems?.data?.length > 0 ? (
              <div className="row product_list_scroll_style">
                <div className="col-12 col-lg-6 col-xl-8">
                  <div className="min-vh-100 product_list_style">
                    {itemsData?.getItems?.data?.map((item) => {
                      return (
                        <Card className="product_card_wrapper_style">
                          <div className="product_img_wrapper_style">
                            <img
                              src={item?.image_url?.small}
                              alt="Image Not Found"
                              onError={(e) => HelperFns.addDefaultSrc(e)}
                            />

                            {item?.is_backorder ? (
                              <Badge className="back_order_badge_style" pill>
                                <p className="mb-0">
                                  Available on <b>Backorder</b>
                                </p>
                              </Badge>
                            ) : null}
                          </div>

                          <CardBody className="p-0">
                            <CardTitle className="product_name_style">
                              {item?.name?.length > 25
                                ? `${item?.name?.substring(0, 25)}...`
                                : item?.name}
                            </CardTitle>

                            <CardSubtitle className="product_quantity_style">
                              Quantity{" "}
                              <span>
                                {item?.quantity ? item?.quantity : "Available"}
                              </span>
                            </CardSubtitle>

                            <CardText className="d-flex justify-content-between align-items-center">
                              <div className="price_badge_style">
                                ${item?.price}
                              </div>

                              <Button
                                className="add_product_to_cart_btn_style"
                                datatestid={`itemTestId-${item?.id}`}
                                onClick={() =>
                                  handleShowItemsCombination(
                                    item?.id,
                                    item?.item_type,
                                    item?.name,
                                    item?.image_url?.small,
                                    item?.price,
                                    item?.is_backorder,
                                    item?.weight,
                                    item?.weight_unit,
                                    item?.quantity,
                                    item?.description
                                  )
                                }
                              >
                                View Item
                                <img
                                  className="ms-2"
                                  src={require("../../Assets/img/arrow-icon.png")}
                                />
                              </Button>
                            </CardText>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </div>

                  <div className="row">
                    <div className="col-12 mb-5 mb-lg-0">
                      <div className="page-paginator">
                        <Pagination
                          key={itemsData?.getItems?.paginatorInfo?.currentPage}
                          count={itemsData?.getItems?.paginatorInfo?.lastPage}
                          boundaryCount={1}
                          siblingCount={0}
                          defaultPage={
                            itemsData?.getItems?.paginatorInfo?.currentPage
                          }
                          renderItem={(item) => (
                            <PaginationItem
                              {...item}
                              className={
                                item?.selected ? "selected" : "notSelected"
                              }
                              onClick={() => handleListPagination(item)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6 col-xl-4">
                  <Cart
                    storeFrontId={stationInfo?.stationId}
                    storeId={stationInfo?.storeId}
                    stationName={stationInfo?.stationName}
                    storeName={stationInfo?.storeName}
                  />

                  <div className="calculator_wrapper_style">
                    <Calculator />
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center p-5 no_result_text_style">
                <div className="row">
                  <div className="col-12">There are no items to display</div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* Start of validate sales person id modal */}
      {isValidateSalesPersonIdModalVissible ? (
        <SalesPersonIdModal
          handleInitStripeTerminal={() => handleInitStripeTerminal()}
          terminalLoader={terminalLoader}
          isStripeTerminalActivated={
            stationStripeInfo?.filter(
              (el) => el?.id === stationInfo?.stationId
            )[0]?.stripe_location_id !== null &&
            stationStripeInfo?.filter(
              (el) => el?.id === stationInfo?.stationId
            )[0]?.terminal_type === paymentStatus.STRIPE_PAYMENT &&
            stationStripeInfo?.filter(
              (el) => el?.id === stationInfo?.stationId
            )[0]?.is_terminal_connected
          }
        />
      ) : null}
      {/* End of validate sales person id modal */}

      {/* Start of stripe terminal disconnected modal */}
      {isStripeTerminalDisconnectedModalVissible ? (
        <StripeTerminalDisconnectedModal
          handleInitStripeTerminal={() => handleInitStripeTerminal()}
          terminalLoader={terminalLoader}
        />
      ) : null}
      {/* End of stripe terminal disconnected modal */}

      {/* Start of success stripe terminal toaster */}
      <Toaster position="top-right" reverseOrder={false} />
      {/* End of success stripe terminal toaster */}

      {/* Start of confirm password modal */}
      {isConfirmPasswordModalVissible ? <ConfirmPasswordModal /> : null}
      {/* End of confirm password modal */}

      {/* Start of open drawer manually modal */}
      {isOpenDrawerManuallyModalVissible ? (
        <OpenDrawerManuallyModal stationId={+stationInfo?.stationId} />
      ) : null}
      {/* End of open drawer manually modal */}
    </>
  );
};

export default Products;
